<template>
    <el-row>
        <el-col>
            <el-card :body-style="{ 'padding': '0' }" @mousemove="startTimer" @mouseout="clearTimer">
                <iframe src="https://www.piccopilot.com/create/?utm_source=aff&utm_medium=yinocloud"
                    id="piccopilotIframe" importance="high" scrolling="no" frameborder="0"></iframe>
            </el-card>
        </el-col>
    </el-row>
</template>

<script setup>
import { setIframeLog } from '@/request/api/common';
import { ref, onMounted, onUnmounted } from 'vue';

const startTime = ref(null);
const stayDuration = ref(0);

const startTimer = () => {
    startTime.value = new Date();
};

const calculateStayDuration = () => {
    if (startTime.value) {
        const endTime = new Date();
        stayDuration.value += (endTime - startTime.value) / 1000;
        startTime.value = null;
    }
};

onMounted(() => {
    startTimer();
});

onUnmounted(() => {
    calculateStayDuration();
    setIframeLog(Math.round(stayDuration.value));
});
</script>

<style lang="less" scoped>
#piccopilotIframe {
    width: 100%;
    height: calc(100vh - 100px);
}
</style>